<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-data-table
          dense
          :headers="cabecalho"
          :items="compCaixasLista"
          class="elevation-10"
          :search="busca"
          :loading="compCaixasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-book-account</v-icon>
              <v-toolbar-title>Caixas</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRefresh()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarCaixaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Caixa</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="busca"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary" show-arrows>
                    <v-chip @click="varlistaCaixas = 'caixas'"> Caixas </v-chip>
                    <v-chip @click="varlistaCaixas = 'lixeira'">
                      Lixeira
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.status="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.id_usuario ? 'green' : 'red'"
                  v-bind="attrs"
                  v-on="on"
                >
                  fiber_manual_record
                </v-icon>
              </template>
              <span>{{
                item.id_usuario ? "Ligado a Usuário" : "Sem Usuário"
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  v-if="varlistaCaixas == 'caixas'"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarCaixaEdicao(item)"
                  color="blue darken-1"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  :color="metLixeiraColor(item.lixeira)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metLixeira(item)"
                >
                  {{ item.lixeira ? "mdi-recycle" : "mdi-trash-can" }}
                </v-icon>
              </template>
              <span>{{ item.lixeira ? "Reciclar" : "Lixeira" }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="red darken-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="metDialogDeletarCaixa(item)"
                  v-if="item.lixeira"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <span>Deletar</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog v-model="varDialogDeletar" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Excluir permanentemente o registro?</v-card-title
            >
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dense
                  class="mb-4 white--text"
                  color="red darken-1"
                  @click="metDeletarCaixa()"
                >
                  Deletar
                </v-btn>
                <v-btn
                  dense
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDialogDeletar()"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
// import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("contCaixas");

export default {
  mixins: [DatasMixin],
  name: "ContCaixasLista",
  data: () => ({
    busca: "",
    cabecalho: [
      { text: "Código", value: "id", align: "start" },
      { text: "Status", value: "status", sortable: false, align: "start" },
      {
        text: "Nome/Razão Social",
        align: "start",
        sortable: true,
        value: "nome_razao_social",
      },
      { text: "CPF/CNPJ", value: "cpf_cnpj", align: "start" },
      { text: "Pessoa", value: "tipo_pessoa", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    varlistaCaixas: "caixas",
    varDeletarId: undefined,
    varDialogDeletar: false,
  }),

  computed: {
    ...mapState(["staErro", "staCaixas", "staCaixaSelecionado"]),
    ...mapGetters(["getCaixas", "getCaixasLix"]),

    compCaixasLista() {
      return this.varlistaCaixas == "caixas"
        ? this.getCaixas
        : this.getCaixasLix;
    },
    compCaixasProgress() {
      return this.staCaixas === null || this.staCaixas.length === 0;
    },
  },

  created() {
    // register(this.$store);
    this.actCaixasLista({ licenca: this.$store.state.login.licenca });
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterCaixas();
  },

  methods: {
    ...mapActions([
      "actCaixasLista",
      "actCaixaSelecionado",
      "actCaixaResetar",
      "actCaixaLixeira",
      "actCaixaDelete",
    ]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    metDeletarCaixa() {
      this.actCaixaDelete({
        id: this.varDeletarId,
        licenca: this.$store.state.login.licenca,
      });
      this.varDialogDeletar = false;
    },

    metFecharDialogDeletar() {
      this.varDialogDeletar = false;
    },

    metDialogDeletarCaixa(item) {
      this.varDeletarId = item.id;
      this.varDialogDeletar = true;
    },

    async metSelecionarCaixaEdicao(caixa) {
      if (caixa && caixa.id) {
        await this.actCaixaSelecionado({
          id: caixa.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        await this.actCaixaResetar();
      }
      this.$router.push({ name: "ContCaixasForm" });
    },

    metLixeiraColor(lixeira) {
      if (lixeira) return "orange darken-2";
      else return "red darken-1";
    },

    metLixeira(caixa) {
      try {
        this.actCaixaLixeira({
          caixa: caixa,
          licenca: this.$store.state.login.licenca,
          modificacao: this.$store.state.login.usuario.usuario,
        });
        this.$root.snackbar.show({
          type: "success",
          message: "Movida para Lixeira.",
        });
      } catch (error) {
        console.log(error);
      }
    },

    metRefresh() {
      this.actCaixasLista({ licenca: this.$store.state.login.licenca });
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },

    metVoltar() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
